import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import JobTiles from "./../components/JobTiles";
import { ImagePageLayout } from "./../components/StandalonePageLayout";
import { H4BigStrong } from "./../components/Typography";

const PressPage = () => {
  const pressQuery = useStaticQuery(graphql`
    {
      pressPage: craftPressPressEntry {
        title
        subtitle
        headerImage {
          ... on Craft_editorialAssets_Asset {
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      pressReleases: allCraftPressReleasePressReleaseEntry(sort: {fields: editorialDate, order: DESC})
			{
        nodes {
          slug
          title
          jobShortDescription
          editorialDate
          pressReleaseDownload {
            url
          }
          headerImage {
            ... on Craft_editorialAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 190) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { title, subtitle, headerImage } = pressQuery.pressPage;
  const pressReleases = pressQuery.pressReleases.nodes;
  // console.log(pressQuery);
  return (
    <Layout
      backgroundColor={"var(--red)"}
      footerColor={"var(--red)"}
      title={title}
      SEODescription={subtitle}
    >
      <ImagePageLayout
        gatsbyImageImage={headerImage[0].wideImage.childImageSharp.fixed}
        overlay={"var(--red)"}
      >
        <h1>{title}</h1>
        <div>
          <div>
            <H4BigStrong>{subtitle}</H4BigStrong>
            <JobTiles
              jobs={pressReleases}
              textIfNoJobs={"No press releases!"}
              isPress
            />
          </div>
        </div>
      </ImagePageLayout>
    </Layout>
  );
};

export default PressPage;
